import request from '@/utils/request'

export const getSimpleList = () => {
  return request({
    method: 'GET',
    url: '/cms/shop_categories/simple_list'
  })
}

export const postShopCategories = (data) => {
  return request({
    method: 'POST',
    url: '/cms/shop_categories',
    data
  })
}

export const getIdPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_categories/${id}/preview`
  })
}

export const putShopCategories = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/shop_categories/${id}`,
    data
  })
}

export const getSimpleListByShopId = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_categories/simple_list_by_shop_id',
    params
  })
}

export const postIdOnline = (id) => {
  return request({
    method: 'POST',
    url: `/cms/shop_categories/${id}/online`
  })
}

export const postIdOffline = (id) => {
  return request({
    method: 'POST',
    url: `/cms/shop_categories/${id}/offline`
  })
}
