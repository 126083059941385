import request from '@/utils/request'

export const getSimpleList = () => {
  return request({
    method: 'GET',
    url: '/cms/shop_sub_categories/simple_list'
  })
}

export const getSimpleListByShopId = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shop_sub_categories/simple_list_by_shop_id',
    params
  })
}

export const postShopSubCategories = (data) => {
  return request({
    method: 'POST',
    url: '/cms/shop_sub_categories',
    data
  })
}

export const putShopSubCategories = (id, data) => {
  return request({
    method: 'PUT',
    url: `/cms/shop_sub_categories/${id}`,
    data
  })
}

export const getIdPreview = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shop_sub_categories/${id}/preview`
  })
}
