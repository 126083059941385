import i18n from '@/i18n/index'

const dataDictionary = {
  // 活动类型
  '001': {
    // 新品
    '001': {
      code: 'new_item'
    },
    // 热销
    '002': {
      code: 'hot_item'
    },
    // 特价
    '003': {
      code: 'discount_item'
    },
    // 秒杀
    '004': {
      code: 'flash_sale'
    },
    // 砍价
    '005': {
      code: 'slash_sale'
    },
    // 拼团
    '006': {
      code: 'group_buy'
    },
    // 预售
    '007': {
      code: 'pre_sale'
    }
  },
  // 商品特别属性
  '002': {
    // 冷藏品
    '001': {
      code: 'refrigerated',
      text: i18n.t('text.refrigeratedProduct')
    },
    // 冷冻品
    '002': {
      code: 'frozen_product',
      text: i18n.t('text.frozenProduct')
    },
    // non halal
    '003': {
      code: 'non_halal',
      text: i18n.t('Non halal')
    }
  },
  // 商品类型
  '003': {
    '001': {
      code: 'food',
      text: '美食'
    },
    '002': {
      code: 'show',
      text: '演出门票',
      name: i18n.t('form.product.show')
    },
    '003': {
      code: 'normal',
      text: '普通商品'
    },
    '004': {
      code: 'pack_info',
      text: '景区门票',
      name: i18n.t('form.product.ticket')
    },
    '005': {
      code: 'hotel_room',
      text: '酒店房间',
      name: i18n.t('form.product.hotelRoom')
    },
    '006': {
      code: 'package',
      text: '套票',
      name: i18n.t('form.zoo.packageName')
    },
    '007': {
      code: 'coupon',
      text: '优惠券',
      name: i18n.t('form.product.offlineCoupon')
    },
    '008': {
      code: 'ticket',
      text: '景点套票',
      name: i18n.t('form.product.ticketPackage')
    },
    '009': {
      code: 'coupon_bundle',
      text: '优惠券礼包',
      name: i18n.t('form.product.voucherPack')
    }
  }
}

export default dataDictionary
